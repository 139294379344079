import React from "react";
import { Route, Switch } from "react-router-dom";
import Album from "./components/pages/Album";
import Session from "./components/pages/Session";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        
        <Route path="/a/:albumkey" exact component={Album} />
        <Route path="/s/:sessionid" exact component={Session} />

      </Switch>
    );
  }
}

export default Routes;
